<template>
    <div>
        <FilterModal
            @reset="resetFilter"
            @filter="getFilterData"
            :isLoading="isLoading"
            ref="filterModal"
        >
            <template v-slot:filters>
                <div v-show="false">
                    <TitleTypeAccountFilter
                        ref="titleTypeAccountFilter"
                        v-model:titleType="filterPayload.titleType"
                        v-model:manCodes="filterPayload.manCodes"
                        :hasSelectedALL="true"
                    />
                </div>
                <Textbox
                    name="userName"
                    title="客戶姓名"
                    type="text"
                    v-model="filterPayload.userName"
                    placeholder="請輸入客戶姓名"
                />
                <Textbox
                    name="endDate"
                    type="date"
                    :title="filterTitles.registerDate"
                    :max="'today'"
                    min="default"
                    :required="false"
                    v-model="filterPayload.registerDate"
                />
            </template>
        </FilterModal>
    </div>
</template>

<script>
import _ from 'lodash'

import FilterModal from '@/containers/FilterModal.vue'
import Textbox from '@/components/Textbox.vue'
import TitleTypeAccountFilter from '@/containers/TitleTypeAccountFilter.vue'

import { ctwCustomerTitles } from '@/assets/javascripts/ctw/setting'
import filterDefault from '@/assets/json/filterDefault.json'

export default {
    name: 'CtwCustomerFilter',
    emits: [
        'show-data-table',
        'update:filterLogProp',
        'update:filterPayloadProp'
    ],
    components: {
        FilterModal,
        Textbox,
        TitleTypeAccountFilter
    },
    props: {
        filterPayloadProp: {
            type: Object,
            default: function () {
                return {
                    manCodes: ''
                }
            }
        },
        filterLogProp: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    methods: {
        /**
         * 重置篩選內容
         */
        resetFilter: function () {
            this.filterPayload = { ...filterDefault.ctwCustomer }
            this.$refs.titleTypeAccountFilter.resetFilter()
            this.filterPayload = this.$getStorePayload(this.filterPayload)
            this.$emit('update:filterPayloadProp', this.filterPayload)
        },
        /**
         * 取得篩選結果
         */
        getFilterData: async function () {
            const isSameValue = this.$isSameValue(
                this.perviousFilterPayload,
                this.filterPayload
            )
            if (!isSameValue) {
                this.perviousFilterPayload = _.cloneDeep(this.filterPayload)
                this.$emit('show-data-table')
            }
            this.filterLog = this.$getFilterLog(this.filterPayload)
            this.hasCrossFilter = false
        }
    },
    computed: {
        filterOptions: function () {
            return {}
        }
    },
    watch: {
        filterPayload: {
            handler() {
                this.$emit('update:filterPayloadProp', this.filterPayload)
            },
            deep: true
        },
        filterLog: {
            handler() {
                this.$emit('update:filterLogProp', this.filterLog)
            },
            deep: true
        }
    },
    data() {
        return {
            isLoading: false,
            filterPayload: this.filterPayloadProp,
            perviousFilterPayload: {},
            filterLog: this.filterLogProp,
            filterTitles: ctwCustomerTitles,
            hasCrossFilter: this.$hasCrossFilter()
        }
    },
    activated() {
        this.hasCrossFilter = this.$hasCrossFilter()
        this.$nextTick(() => {
            this.resetFilter()
            if (this.hasCrossFilter) this.getFilterData()
        })
    }
}
</script>

<style lang="scss" scoped></style>
